import { map } from 'lodash'

export const tableOption = {
  column: [
    {
      prop: 'supplierName',
      label: '供应商名称',
      search: true
    },
    {
      prop: 'contactName',
      label: '联系人'
    },
    {
      prop: 'phone',
      label: '联系方式'
    },
    {
      prop: 'address',
      label: '地址'
    },
    {
      prop: 'businessLicense',
      label: '营业执照'
    },
    {
      prop: 'relevanceStyleList',
      label: '管理的款式',
      formatter(row) {
        return map($GET(row, 'relevanceStyleList', []), 'name').join('、')
      }
    },
    {
      prop: 'createTime',
      label: '时间'
    }
  ]
}
